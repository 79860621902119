import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import UserActions from '../views/UserActions/UserActions.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
//   {
//     path: '/Home/UserActions',
//     name: "UserActions",
//     component: UserActions
//   },
]

const router = createRouter({
  history: createWebHashHistory(), // createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  // NOTE! Window.vm.redirectHomeToDefaultRoute & disableDashboard are set at WiseApp.vue "created()", after fetching default configuration from JSON-file

  console.log('route change');
  if ( to.path === '/' && window.vm.config?.redirectHomeToDefaultRoute){
    next({
      path: window.vm.config?.defaultRoute,
      replace: true
    })
  } else {
    next();
  }
})

export default router
