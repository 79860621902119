import { createApp } from 'vue'
import App from './views/customer-portal-app.vue'
import router from './router/index.js'
import store from './store/customer-portal-store'

import "bootstrap/dist/css/bootstrap.min.css"
import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap;

import dayjs from 'dayjs'
import 'dayjs/locale/fi';
import "vue-multiselect/dist/vue-multiselect.css";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/bootstrap.min.css";

import qsStringify from 'qs-stringify';
window.stringify = qsStringify;

import axios from "axios";
window.axios = axios;

import alertify from "alertifyjs"

alertify.defaults.transition = "slide";
alertify.defaults.theme.ok = "btn btn-light";
alertify.defaults.theme.cancel = "btn btn-danger";
alertify.defaults.theme.input = "form-control";
alertify.prompt().set({labels:{ ok: 'OK', cancel: 'Peruuta'} });
window.alertify = alertify;

import Multiselect from 'vue-multiselect';
// import {  DatePicker } from 'v-calendar';

import 'v-calendar/dist/style.css';

import Lodash from 'lodash'

import $t from './plugins/$t';

var customParseFormat = require('dayjs/plugin/customParseFormat')

var utc = require('dayjs/plugin/utc')
var duration = require('dayjs/plugin/duration')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.locale('fi');

import VueUploadComponent from 'vue-upload-component'
import FormInput from './components/shared/form-input'


window.$dayjs = dayjs;

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faImages, faBars, faClock, faEnvelope, faArrowDown, faChartPie, faFileAlt, faCheckCircle, faMap, faWindowClose, faGlobe, faChartBar, faCogs, faRedo, faUndo, faExchangeAlt, faSave, faFileDownload, faChartArea, faQuestion, faChevronLeft, faChevronRight, faLink, faAngleUp, faAngleDown, faPlus, faPlusCircle, faTrash, faBoxes, faFile, faBook, faBriefcase, faBusinessTime, faEye, faArrowLeft, faFileImport, faClipboardCheck, faPencilAlt, faPlay, faMinus, faExpand, faPause  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faImages, faBars, faClock, faEnvelope, faArrowDown, faChartPie, faFileAlt, faCheckCircle, faMap, faWindowClose, faGlobe, faChartBar, faCogs, faRedo, faUndo, faExchangeAlt, faSave, faFileDownload, faChartArea, faQuestion, faChevronLeft, faChevronRight, faLink, faAngleUp, faAngleDown, faPlus, faPlusCircle, faTrash, faBoxes, faSave, faFile, faBook, faBriefcase, faBusinessTime, faEye, faArrowLeft, faFileImport, faClipboardCheck, faPencilAlt, faPlay, faMinus, faExpand, faPause)


const Wise = createApp(App)
    .use(store)
    .use(router)
    .use($t);

// Wise.use(setupCalendar, {});
// Wise.component('DatePicker', DatePicker);
Wise.component('multiselect', Multiselect);
Wise.component('font-awesome-icon', FontAwesomeIcon)
Wise.component('file-upload', VueUploadComponent)
Wise.component('form-input', FormInput)
Wise.config.globalProperties.$dayjs = dayjs;
Wise.config.globalProperties.$_ = Lodash;
Wise.config.globalProperties.$vm = window.vm;
Wise.config.globalProperties.$alertify = window.alertify;
Wise.config.globalProperties.$axios = window.axios;

Wise.config.globalProperties.$getAssetImagePath = (filename) =>  {
    var s= window.vm.basePath + 'images/' + filename;
    console.log(s);
    return s;
}

Wise.config.globalProperties.$formatCurrency = (amount) => {
    return amount.toLocaleString('fi-FI', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
};

Wise.config.globalProperties.$formatDate = (date, format) => {
    return date ? dayjs(date).format(
        format ? format : 'DD.MM.YYYY HH:mm'
    ) : "";
};

Wise.mount('#extranet-app');
